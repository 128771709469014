.intro-box {
  position: relative;
  display: grid;
  grid-template-columns: calc(100% - 70px) 70px;
  gap: 0.5rem;
  align-items: center;
  background-color: var(--first-color);
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  overflow: hidden;
}
.intro-box::before {
  content: "";
  position: absolute;
  left: 40%;
  bottom: -25px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 7px solid var(--opacity-first);
  z-index: 0;
}
.intro-box::after {
  content: "";
  position: absolute;
  left: -25px;
  top: -25px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--opacity-first);
  z-index: 0;
}
.intro-texts {
  z-index: 9;
}
.intro-title {
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: var(--light-color);
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.intro-description {
  font-size: 1rem;
  color: var(--text-color);
}
.intro-icon {
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  border-radius: 0.5rem;
  background-color: var(--opacity-first);
  z-index: 9;
}
.intro-icon i {
  font-size: 1.25rem;
  color: var(--text-color);
}
.level-boxes {
  margin-top: 1rem;
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem; */
}
.level-box {
  width: 100%;
  border-radius: 1rem;
  background-color: var(--second-color);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.level-text {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
}
.level-name {
  font-size: 2rem;
  color: var(--text-color);
  font-weight: 600;
}
.level-text span {
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: var(--text-color);
}
.level-link {
  display: inline-block;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
  border-radius: 0.5rem;
  background-color: var(--opacity-second);
  padding: 0.75rem;
  text-decoration: none;
  color: var(--text-color);
}
